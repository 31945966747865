import { useContext } from "react";

import { StateContext } from "reducer/constants";
import { NFTFactory } from "utils/api";

import { useParameter } from "./useParameter";

export const useLevel = () => {
    const { currentAddress } = useContext(StateContext);
    const level = useParameter(`level ${currentAddress}`, NFTFactory.getLevel, currentAddress);

    return level ? level / 1000 : undefined;
};
