import React from "react";
import { Switch, Route } from "react-router-dom";

import { PageInfo } from "pages/PageInfo";

interface Props {
    onConnect: () => void;
}

export const Routes = ({ onConnect }: Props) => (
    <Switch>
        {/* @ts-ignore */}
        <Route exact path="/">
            <PageInfo />
        </Route>
    </Switch>
);
