import { useContext } from "react";
import BigNumber from "bignumber.js";

import { StateContext } from "reducer/constants";
import { TimelockFactory } from "utils/api";
import { createBNeDecimal } from "utils/bigNumber";
import { useParameter } from "./useParameter";

export const useTimelockData = () => {
    const { currentAddress } = useContext(StateContext);
    const data = useParameter(`timeLock data`, TimelockFactory.getData, currentAddress);

    return data
        ? {
              tokensClaimed: new BigNumber(data.amount_already_withdrawn_div1e12).multipliedBy(createBNeDecimal(12)),
              tokensBought: new BigNumber(data.amount_under_vesting_div1e12).multipliedBy(createBNeDecimal(12)),
              fullVestingTimestamp: data.vesting_ends_timestamp,
              cliffTimestamp: data.cliff_timestamp,
          }
        : undefined;
};
