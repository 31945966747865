import { useContext, useEffect, useState } from "react";

import { StateContext } from "reducer/constants";

const TIMESTAMP_INTERVAL_DELAY = 10000;

export const useTimestamp = () => {
    const { web3 } = useContext(StateContext);
    const [currentTimestamp, setCurrentTimestamp] = useState<number>();

    const getTimestamp = async () => {
        if (web3) {
            const block = await web3.eth.getBlockNumber();
            const blockInfo = await web3.eth.getBlock(block);
            setCurrentTimestamp(+blockInfo.timestamp);
        } else {
            setCurrentTimestamp(undefined);
        }
    };

    useEffect(() => {
        getTimestamp();
        const intervalTimestamp = setInterval(getTimestamp, TIMESTAMP_INTERVAL_DELAY);

        return () => {
            clearInterval(intervalTimestamp);
        };
    }, [web3]);

    return currentTimestamp;
};
