import React from "react";

import { useTimelockData, useTimestamp } from "hooks";
import { getDateLeft, TIME_OVER_TEXT } from "utils/date";
import { toHRNumber } from "utils/bigNumber";
import { Claim } from "./_supportComponents/Claim/Claim";
import { Info } from "./_supportComponents/Info/Info";

import "./styles.scss";

export const PageInfo = () => {
    const currentTimestamp = useTimestamp();

    const data = useTimelockData();

    const realTokenBought = data ? toHRNumber(data.tokensBought, 18) : 0;
    const realTokenClaimed = data ? toHRNumber(data.tokensClaimed, 18) : 0;

    return (
        <div className="ContentContainer">
            <div className="ContentContainer__timestamps">
                <Info
                    title="Cliff date"
                    value={
                        currentTimestamp && data ? getDateLeft(currentTimestamp, data.cliffTimestamp) : TIME_OVER_TEXT
                    }
                    tooltip="cliff: rewards tokens are locked until this date. vesting: tokens are unlock gradually from cliff date until fully vested."
                />
                <Info
                    title="Full vesting date"
                    value={
                        currentTimestamp && data
                            ? getDateLeft(currentTimestamp, data.fullVestingTimestamp)
                            : TIME_OVER_TEXT
                    }
                />
            </div>
            <Claim
                realTokenBought={realTokenBought}
                realTokenClaimed={realTokenClaimed}
                cliffTimestamp={data?.cliffTimestamp}
            />
        </div>
    );
};
