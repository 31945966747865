import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import BigNumber from "bignumber.js";
import { useGlobalUpdate } from "hooks";
import { ButtonGradient } from "ui-kit";
import { CONTRACT_ADDRESS_SUDAO, CONTRACT_ADDRESS_VE_SUDAO } from "contracts/contract";
import { StateContext } from "reducer/constants";
import { TimelockFactory } from "utils/api";
import { toHRNumber } from "utils/bigNumber";
import { addToMetamask } from "utils/currency";
import { addErrorNotification, addSuccessNotification } from "utils/notification";
import { Balance } from "../Balance/Balance";
import { ErrorText, ErrorType } from "../ErrorText/ErrorText";

import "./Claim.css";

interface ClaimProps {
    cliffTimestamp: number;
    realTokenClaimed: number;
    realTokenBought: number;
}

const SET_INTERVAL_DELAY = 10000;

export const Claim = ({ cliffTimestamp, realTokenClaimed, realTokenBought }: ClaimProps) => {
    const { web3, currentAddress } = useContext(StateContext);
    const [isLoadingClaim, setIsLoadingClaim] = useState(false);
    const [isClaimed, setIsClaimed] = useState(false);
    const [cliffTimePassed, setCliffTimePassed] = useState(false);
    const [availableToClaim, setAvailableToClaim] = useState<BigNumber>();
    const [lockedBalance, setLockedBalance] = useState<BigNumber>();
    const { update } = useGlobalUpdate();

    const [error, setError] = useState("");
    const [errorType, setErrorType] = useState<ErrorType | undefined>(undefined);

    const getAvailableToClaim = async () => {
        setAvailableToClaim(await TimelockFactory.getAvailableToClaim());
    };

    const updateBalance = async () => {
        setLockedBalance(await TimelockFactory.balance());
    };

    useEffect(() => {
        updateBalance();
    }, [realTokenBought, currentAddress]);

    const handleUpdate = () => {
        updateBalance();
        update();
    };

    const handleClaim = async () => {
        try {
            setIsClaimed(false);
            setIsLoadingClaim(true);
            await TimelockFactory.claim();
            await getAvailableToClaim();
            handleUpdate();
            setIsClaimed(true);
            setIsLoadingClaim(false);
            addSuccessNotification("Claimed!");
        } catch (e) {
            setIsLoadingClaim(false);
            setIsClaimed(false);
            addErrorNotification("Claim failed", e.message);
        }
    };

    const checkAvailableToCliff = async () => {
        if (web3) {
            const block = await web3.eth.getBlockNumber();
            const blockInfo = await web3.eth.getBlock(block);
            if (blockInfo.timestamp < cliffTimestamp) {
                setCliffTimePassed(false);
                setError("Сliff time has not come yet");
                setErrorType("warning");
            } else {
                setError("");
                setCliffTimePassed(true);
            }
        }
    };

    useEffect(() => {
        checkAvailableToCliff();
        const intervalAvailableToCliff = setInterval(() => {
            checkAvailableToCliff();
        }, SET_INTERVAL_DELAY);

        getAvailableToClaim();
        const intervalAvailableToClaim = setInterval(() => {
            getAvailableToClaim();
        }, SET_INTERVAL_DELAY);

        return () => {
            clearInterval(intervalAvailableToCliff);
            clearInterval(intervalAvailableToClaim);
        };
    }, [currentAddress, cliffTimestamp]);

    const availableToClaimHR = useMemo(
        () => (availableToClaim ? toHRNumber(availableToClaim, 18) : 0),
        [availableToClaim]
    );
    const realLockedBalance = useMemo(() => (lockedBalance ? toHRNumber(lockedBalance, 18) : 0), [lockedBalance]);

    const handleAddTokenToWallet = useCallback(() => {
        addToMetamask({
            address: CONTRACT_ADDRESS_SUDAO,
            symbol: "suDAO",
            decimals: 18,
            image: "https://stableunit.org/assets/img/logo.svg",
        });
    }, []);

    const handleAddVeTokenToWallet = useCallback(() => {
        addToMetamask({
            address: CONTRACT_ADDRESS_VE_SUDAO,
            symbol: "veSuDAO",
            decimals: 18,
            image: "https://stableunit.org/assets/img/logo.svg",
        });
    }, []);

    return (
        <div className="Claim">
            <Balance title="Vesting balance" value={`${realLockedBalance.toLocaleString()} suDAO`}>
                <div className="Claim__addToken--inner" onClick={handleAddVeTokenToWallet}>
                    Add veSuDAO to Metamask
                </div>
            </Balance>
            <div className="Claim__info">
                <div className="Claim__info__container">
                    <div className="Claim__info__title">Available to claim</div>
                    <div className="Claim__info__value">
                        {availableToClaimHR.toLocaleString()}
                        <span className="Claim__info__currency">&nbsp;suDAO</span>
                    </div>
                </div>
                <div className="Claim__info__container">
                    <div className="Claim__info__title">Already claimed</div>
                    <div className="Claim__info__value">
                        {realTokenClaimed.toLocaleString()}
                        <span className="Claim__info__currency">&nbsp;suDAO</span>
                    </div>
                </div>
            </div>
            <ErrorText text={error} type={errorType} />
            <div className="Claim__buttons">
                <ButtonGradient
                    onClick={handleClaim}
                    disabled={isLoadingClaim || availableToClaimHR <= 0 || !cliffTimePassed}
                >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {isLoadingClaim ? "PENDING..." : isClaimed ? "CLAIMED" : "CLAIM TOKENS"}
                </ButtonGradient>
            </div>
            <div className="Claim__addToken" onClick={handleAddTokenToWallet}>
                Add suDAO to Metamask
            </div>
        </div>
    );
};
