import React from "react";
import { SocialNetwork } from "ui-kit";

import "./styles.scss";

export const Footer = () => {
    return (
        <div className="footer">
            <SocialNetwork name="tg" />
            <SocialNetwork name="discord" />
            <SocialNetwork name="medium" />
            <SocialNetwork name="twitter" />
        </div>
    );
};
