import React from "react";
import { NavLink } from "react-router-dom";

import tokenLogo from "ui-kit/images/currency/USDPro.svg";

import "./styles.scss";

export const USDProInfo = () => {
    return (
        <div className="usd-pro-info">
            <NavLink to="/">
                <img src={tokenLogo} width={48} height={48} />
            </NavLink>
        </div>
    );
};
