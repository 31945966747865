import Web3 from "web3";
import BigNumber from "bignumber.js";
import { Contract } from "web3-eth-contract";

import CONTRACT_ERC20 from "contracts/ERC20.json";

import {
    CONTRACT_ADDRESS_TIMELOCK,
    CONTRACT_ADDRESS_DISTRIBUTOR,
    CONTRACT_ADDRESS_SUDAO,
    CONTRACT_ADDRESS_A_NFT,
} from "contracts/contract";
import CONTRACT_SUDAO from "contracts/suDAO.json";
import CONTRACT_DISTRIBUTOR from "contracts/distributor.json";
import CONTRACT_TIMELOCK from "contracts/timelock.json";
import CONTRACT_A_NFT from "contracts/aNFT.json";
import { SupportedTokensType } from "./currency";

type ContractsType = "TimelockContract" | "DistributorContract" | "SuDAOContract" | "ANFTContract";

export const contracts: Record<ContractsType, Contract | undefined> = {
    SuDAOContract: undefined,
    TimelockContract: undefined,
    DistributorContract: undefined,
    ANFTContract: undefined,
};

export const initAllContracts = (web3: Web3) => {
    setSuDAOContract(new web3.eth.Contract(CONTRACT_SUDAO as any, CONTRACT_ADDRESS_SUDAO));
    setTimelockContract(new web3.eth.Contract(CONTRACT_TIMELOCK as any, CONTRACT_ADDRESS_TIMELOCK));
    setDistributorContract(new web3.eth.Contract(CONTRACT_DISTRIBUTOR as any, CONTRACT_ADDRESS_DISTRIBUTOR));
    setANFTContract(new web3.eth.Contract(CONTRACT_A_NFT as any, CONTRACT_ADDRESS_A_NFT));
};

export const setTimelockContract = (newContract: Contract) => {
    contracts.TimelockContract = newContract;
};
export const setDistributorContract = (newContract: Contract) => {
    contracts.DistributorContract = newContract;
};
export const setSuDAOContract = (newContract: Contract) => {
    contracts.SuDAOContract = newContract;
};
export const setANFTContract = (newContract: Contract) => {
    contracts.ANFTContract = newContract;
};

let currentAddress: string;
export const setUtilsCurrentAddress = (newAddress: string) => {
    currentAddress = newAddress;
};

let web3: Web3;
export const setUtilsWeb3 = (newWeb3: Web3) => {
    web3 = newWeb3;
};

const tryToRunLocal = async (command: any, options: Record<string, any> = {}) => {
    await command.estimateGas({ from: currentAddress, ...options });
    return command;
};

export const DistributorFactory = {
    getData: async (distributionId: number) => {
        if (contracts.DistributorContract) {
            return contracts.DistributorContract.methods.distributions(distributionId).call();
        }
        return undefined;
    },
};

export const TimelockFactory = {
    getData: async () => {
        if (contracts.TimelockContract && currentAddress) {
            return contracts.TimelockContract.methods.accounts(currentAddress).call();
        }
        return undefined;
    },
    getAvailableToClaim: async () => {
        if (contracts.TimelockContract && currentAddress) {
            return new BigNumber(await contracts.TimelockContract.methods.availableToClaim(currentAddress).call());
        }
        return undefined;
    },
    balance: async () => {
        if (contracts.TimelockContract && currentAddress) {
            return new BigNumber(await contracts.TimelockContract.methods.balanceOf(currentAddress).call());
        }
        return undefined;
    },
    claim: async () => {
        if (contracts.TimelockContract && currentAddress) {
            await contracts.TimelockContract.methods.claim().send({ from: currentAddress });
        }
    },
};

export const NFTFactory = {
    getLevel: async () => {
        if (contracts.ANFTContract && currentAddress) {
            return +(await contracts.ANFTContract.methods.getLevel(currentAddress).call());
        }
        return undefined;
    },
};

export const CommonFactory = {
    balance: async (tokenAddress?: string) => {
        if (!web3 || !tokenAddress || !currentAddress) {
            return new BigNumber(0);
        }

        const tokenContract = new web3.eth.Contract(CONTRACT_ERC20 as any, tokenAddress);
        return new BigNumber(await tokenContract.methods.balanceOf(currentAddress).call());
    },
    decimals: async (tokenAddress?: string) => {
        if (!web3 || !tokenAddress) {
            return 0;
        }

        const tokenContract = new web3.eth.Contract(CONTRACT_ERC20 as any, tokenAddress);
        return +(await tokenContract.methods.decimals().call());
    },
    symbol: async (tokenAddress?: string) => {
        if (!web3 || !tokenAddress) {
            return undefined;
        }

        const tokenContract = new web3.eth.Contract(CONTRACT_ERC20 as any, tokenAddress);
        return (await tokenContract.methods.symbol().call()) as SupportedTokensType;
    },
};
